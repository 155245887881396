main {
  height: 100%;
}

main .card {
  background-color: rgba(205, 205, 205, 0.3);
  padding: 2rem;
  border-radius: 0;
  margin-top: -3rem;
  width: 100%;
  border: 0;
  box-shadow: 0px 1px 5px rgba(97, 97, 97, 0.16);
}
main a {
  color: #000000;
  text-decoration: underline;
}
