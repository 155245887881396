@import url("https://fonts.googleapis.com/css?family=Roboto:200&display=swap");
@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
html {
  height: 100%;
}
body {
  background-image: url(./assets/images/640.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  font-family: "Roboto", sans-serif;
  height: 100%;
}
#root {
  height: 100%;
}
@media (min-width: 640px) {
  body {
    background-image: url(./assets/images/1024.jpg);
  }
}
@media (min-width: 1024px) {
  body {
    background-image: url(./assets/images/1280.jpg);
  }
}
@media (min-width: 1280px) {
  body {
    background-image: url(./assets/images/1920.jpg);
  }
}
@media (min-width: 1920px) {
  body {
    background-image: url(./assets/images/3000.jpg);
  }
}
@media (min-width: 3000px) {
  body {
    background-image: url(./assets/images/4000.jpg);
  }
}
@media (min-width: 4000px) {
  body {
    background-image: url(./assets/images/6000.jpg);
  }
}
