@import url(https://fonts.googleapis.com/css?family=Roboto:200&display=swap);
html {
  height: 100%;
}
body {
  background-image: url(/static/media/640.56bf5f04.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top center;
  font-family: "Roboto", sans-serif;
  height: 100%;
}
#root {
  height: 100%;
}
@media (min-width: 640px) {
  body {
    background-image: url(/static/media/1024.2a0d25cd.jpg);
  }
}
@media (min-width: 1024px) {
  body {
    background-image: url(/static/media/1280.6413b0ff.jpg);
  }
}
@media (min-width: 1280px) {
  body {
    background-image: url(/static/media/1920.5b5be2f6.jpg);
  }
}
@media (min-width: 1920px) {
  body {
    background-image: url(/static/media/3000.ed124089.jpg);
  }
}
@media (min-width: 3000px) {
  body {
    background-image: url(/static/media/4000.2122f145.jpg);
  }
}
@media (min-width: 4000px) {
  body {
    background-image: url(/static/media/6000.5c9bb158.jpg);
  }
}

main {
  height: 100%;
}

main .card {
  background-color: rgba(205, 205, 205, 0.3);
  padding: 2rem;
  border-radius: 0;
  margin-top: -3rem;
  width: 100%;
  border: 0;
  box-shadow: 0px 1px 5px rgba(97, 97, 97, 0.16);
}
main a {
  color: #000000;
  text-decoration: underline;
}

